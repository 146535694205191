<template>
  <div class="contentView orderManageDetail">
    <div class="orderManageDetail-tips flexS" v-if="tableType == 'receive' && tipsShow">
      <span>{{ t('orderManageDetail.tips') }}</span>
      <div class="orderManageDetail-tips-close-icon" @click="tipsShow = false">
        <img src="@/assets/supplier/images/orderManage/tips-close-icon.png" />
      </div>
    </div>
    <div class="centerView order-info-wrap">
      <div class="order-info-left address-info-wrap">
        <img class="address-info-img" src="@/assets/supplier/images/orderManage/address-icon.png" />
        <div class="address-info-detail flexTL">
          <div class="address-info-order-code">#{{ detailData.orderSn }}</div>
          <div class="address-info-msg">
            <div>
              {{ t('addressInfo.receiver') }}：{{ detailData.receiverName || '-' }} - {{ t('addressInfo.phone') }}：{{ detailData.receiverPhone || '-' }} -
              {{ t('addressInfo.postal') }}：{{ detailData.receiverPostcode || '-' }}
            </div>
            <div>{{ t('addressInfo.address') }}：{{ detailData.receiverAddress || '-' }}</div>
          </div>
        </div>
      </div>
      <div class="order-info-right order-statistics-wrap">
        <div class="order-statistics-item">
          <div class="label">{{ t('orderManageDetail.purchaseQuantity') }}</div>
          <div class="value">{{ detailData.dissentQuantity || 0 }}</div>
        </div>
        <div class="order-statistics-item">
          <div class="label">{{ t('orderManageDetail.purchaseAmount') }}</div>
          <div class="value">{{ detailData.dissentAmount || 0 }}</div>
        </div>
        <div class="order-statistics-item">
          <div class="label expressFees">
            <a-tooltip>
              <template #title>{{ t('orderManageDetail.expressFeesTips') }}</template>
              <img
                v-if="
                  tableType == 'receive' &&
                  ((pageType == 'view' && [10, 12].includes(Number(detailData.status))) || (pageType == 'add' && [10, 12].includes(Number(detailData.status))))
                "
                class="label-img"
                src="~@/assets/supplier/images/deliveryManage/address-edit-icon.png"
                @click="editExpressFeesFn"
              />
            </a-tooltip>
            {{ t('orderManageDetail.expressFees') }}
          </div>
          <div class="value">{{ detailData.expressFees || 0 }}</div>
        </div>
      </div>
    </div>
    <ReceiveOrderTable
      v-if="tableType == 'receive'"
      ref="ReceiveOrderTableRef"
      :data="tableData"
      :otherData="detailData"
      :tableLoading="tableLoading"
      @exportOrderList="exportOrderList"
      @closeDrawerUpdateList="closeDrawerUpdateList"
      :pageType="pageType"
      :tableType="tableType"
      :id="id"
    />
    <DeliveryTable
      v-if="tableType == 'delivery'"
      :data="tableData"
      :otherData="detailData"
      :pageType="pageType"
      :tableType="tableType"
      :id="id"
      ref="DeliveryTableRef"
      :tableLoading="tableLoading"
      @getData="getDetailDataFn"
      @exportOrderList="exportOrderList"
      @closeModal="closeModal"
    />

    <CustomModal
      modalWidth="380px"
      v-model:modalVisible="expressFeesModalVisible"
      :modalTitle="t('orderManageDetail.expressFeesTips')"
      :submitting="submittingFlag"
      @submit="expressFeesModalSubmit"
    >
      <div class="flexL">
        <a-input allowClear size="large" v-model:value.trim="expressFeesTemp"></a-input>
      </div>
    </CustomModal>
  </div>
</template>

<script setup>
  /* eslint-disable */
  import { ref, defineExpose, defineEmits } from 'vue'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import ReceiveOrderTable from './components/ReceiveOrderTable'
  import DeliveryTable from './components/DeliveryTable'
  import CustomModal from '@/views/supplier/components/CustomModal'

  import { createExportTask, getTaskResult } from '@/api/supplier/data-workers'
  import { getOrderManageDetail, getOrderManageShippingDetail } from '@/api/supplier/orderManagement/orderManage'
  const emit = defineEmits(['closeModal', 'closeDrawerUpdate'])
  // export default {
  //   name: 'orderManageDetail',
  //   components: {
  //     ReceiveOrderTable,
  //     DeliveryTable,
  //     CustomModal,
  //   },
  //   setup() {
  const route = useRoute()
  const { t, locale } = useI18n()
  // const pageType = route.query.type
  // const tableType = route.query.tableType
  const pageType = ref()
  const tableType = ref()
  // const id = route.query.id
  const id = ref()

  const tipsShow = ref(true)

  const detailData = ref({})

  const tableData = ref([])
  const tableLoading = ref(false)
  function getOrderManageDetailFn() {
    tableLoading.value = true
    tableData.value = []
    getOrderManageDetail({ id: id.value })
      .then(res => {
        tableLoading.value = false
        if (res.code === 200) {
          detailData.value = res.data
          console.log(detailData.value, 'detailData.value-----detailData.value')
          tableData.value = res.data.purchaseOrderDetailList || []
          ReceiveOrderTableRef.value?.setId(id.value)
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {
        tableLoading.value = false
      })
  }
  //接单提交之后逻辑
  const closeDrawerUpdateList = () => {
    emit('closeDrawerUpdate')
  }
  function getOrderManageShippingDetailFn() {
    tableLoading.value = true
    tableData.value = {}
    getOrderManageShippingDetail({ id: id.value })
      .then(res => {
        tableLoading.value = false
        if (res.code === 200) {
          const { purchaseOrderDetailList = [], shippingBos = [] } = res.data || {}
          detailData.value = res.data
          tableData.value = { purchaseOrderDetailList, shippingBos }
        } else {
          // message.error(res.msg)
        }
      })
      .catch(() => {
        tableLoading.value = false
      })
  }

  function getDetailDataFn(type, tabletype, ID) {
    console.log(ID, 'id---123123')
    console.log(type, tabletype, id, 'type, tabletype, id----type, tabletype, id')
    pageType.value = type
    tableType.value = tabletype
    id.value = ID
    tableType.value == 'receive' && getOrderManageDetailFn()
    tableType.value == 'delivery' && getOrderManageShippingDetailFn()
  }

  // getDetailDataFn()

  /* 编辑运费 */
  const expressFeesModalVisible = ref(false)
  const expressFeesTemp = ref(0)
  const submittingFlag = ref(false)

  function editExpressFeesFn() {
    expressFeesTemp.value = detailData.value.expressFees
    expressFeesModalVisible.value = true
  }

  function expressFeesModalCancel() {
    expressFeesModalVisible.value = false
    expressFeesTemp.value = 0
  }

  function expressFeesModalSubmit() {
    detailData.value.expressFees = expressFeesTemp.value
    expressFeesModalCancel()
  }

  //导出订单方法
  const ReceiveOrderTableRef = ref()
  const DeliveryTableRef = ref()
  const downloadToken = ref() //导出明细token
  const exportOrderList = async () => {
    downloadToken.value = ''
    let selelist = {
      purchaseOrderId: id.value
    }
    let payload = {
      projectCode: 'SUPPLIER_ORDER_DETAIL_EXPORT',
      dataParam: JSON.stringify(selelist)
    }
    const { code, data } = await createExportTask(payload)
    if (code == 200) downloadToken.value = data
    // spinRef.value = true
    await getTaskResultFn(data)
  }
  const countNum = ref('')
  //下载列表轮训
  const getTaskResultFn = () => {
    getTaskResult({ taskToken: downloadToken.value })
      .then(res => {
        if (res.code == 200) {
          // 任务状态
          //   0、待处理
          //   1、成功
          //   9、失败
          if (countNum.value > 5 && res.data.state == 0) {
            countNum.value = 0
            // spinRef.value = false
            if (tableType.value == 'receive') {
              ReceiveOrderTableRef.value.loadingTypeFun()
            } else {
              DeliveryTableRef.value.loadingTypeFun()
            }
            return message.error('下载失败，请稍后重试')
          }
          switch (res.data.state) {
            case 0:
              countNum.value++
              setTimeout(getTaskResultFn, 2000)
              break
            case 1:
              countNum.value = 0
              // spinRef.value = false
              if (tableType.value == 'receive') {
                ReceiveOrderTableRef.value.loadingTypeFun()
              } else {
                DeliveryTableRef.value.loadingTypeFun()
              }
              res.data.fileUrl && (window.location.href = res.data.fileUrl)
              break
            case 9:
              countNum.value = 0
              if (tableType.value == 'receive') {
                ReceiveOrderTableRef.value.loadingTypeFun()
              } else {
                DeliveryTableRef.value.loadingTypeFun()
              }
              // spinRef.value = false
              message.error(res.data.resultMessage || '下载失败')
              break
            default:
              break
          }
        }
      })
      .catch(() => {
        countNum.value = 0
        // spinRef.value = false
      })
  }
  const closeModal = () => {
    emit('closeModal')
  }
  defineExpose({ getDetailDataFn })
  //     return {
  //       t,
  //       tipsShow,
  //       pageType,
  //       tableType,
  //       detailData,
  //       tableData,
  //       tableLoading,
  //       getDetailDataFn,
  //       editExpressFeesFn,
  //       expressFeesModalVisible,
  //       expressFeesTemp,
  //       submittingFlag,
  //       expressFeesModalCancel,
  //       expressFeesModalSubmit,
  //     }
  //   },
  // }
</script>

<style lang="less">
  .orderManageDetail {
    position: relative;
    padding-top: 36px;
    .centerView {
      // margin-bottom: 15px !important;
    }
    .select {
      margin: 0 !important;
    }

    .orderManageDetail-tips {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      padding: 0 32px;
      box-sizing: border-box;
      height: 36px;
      line-height: 36px;
      color: #ff6700;
      font-size: 14px;
      // font-weight: 300;
      text-align: space-between;
      background-color: rgba(255, 103, 0, 0.08);
    }
    .orderManageDetail-tips-close-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 1;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
    .order-info-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 30px;
    }
    .address-info-wrap {
      display: flex;
      .address-info-img {
        display: inline-block;
        width: 80px;
        height: 80px;
      }
      .address-info-detail {
        margin-left: 20px;
        color: #131011;
        font-size: 16px;
        line-height: 27px;
      }
      .address-info-order-code {
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 10px;
      }
    }
    .order-statistics-wrap {
      display: flex;
      .order-statistics-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 150px;
        height: 76px;
        border-radius: 8px;
        background-color: #fafafa;
        padding: 12px 8px 8px;
        &:not(:last-child) {
          margin-right: 20px;
        }
        .label {
          width: 100%;
          color: #7a7576;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &.expressFees {
            justify-content: space-between;
          }

          .label-img {
            width: 18px;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .value {
          color: #ff6700;
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
</style>
